import React, {useState, useEffect} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layoutSecure'
import PortableText from '../components/portableText'
import {isUserInRegion} from '../services/auth'
import RestrictContentError from '../components/restrict-content-error'
import {toPlainText} from '../lib/helpers'
import { useSelector, useDispatch } from "react-redux"


export const query = graphql`
    query BasicPageTemplateQuery($id: String!) {
        page: sanityBasicPage(id: {eq: $id}) {
            _id
            publishedAt
            title
            slug {
                current
            }
            banner {
                alt
                asset {
                    url
                }
            }
            _rawBody(resolveReferences: {maxDepth: 5})
            region

        }

        setting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }
    }
`


const BasicPageTemplate = props => {
    const {data, errors} = props

    const page = data && data.page
    if(!page){
        throw new Error(
            'Missing "page data".'
        )
    }

    // console.log('----------> page data: ', props)

    let  banner = page.banner && page.banner.asset ? page.banner.asset.url : ''

    const userInfo = useSelector(state => state.userInfo)

    let userRegion = userInfo ? userInfo.region : ''

    if(!isUserInRegion(userRegion, page.region)){
        return (
            <Layout>
                <Container>
                    <RestrictContentError />
                </Container>
            </Layout>
        )
    }
    

    let setting = (data || {}).setting

    return (
        <Layout>
            {/* {errors && <SEO title='GraphQL Error' />}
            {post && <SEO title={post.title || 'Untitled'} description={toPlainText(post._rawExcerpt)} image={post.mainImage} />} */}

            {page && <SEO title={page.title || 'Untitled'} description={toPlainText(page._rawPageDescription) || setting.description} />}

            {errors && (
                <Container>
                    <GraphQLErrorList errors={errors} />
                </Container>
            )}
            
            
            {/* Main Content */}
            {/* image */}
            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={page.banner ? page.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className="mb-0" style={{color: 'rgb(245, 235, 220)'}}>{page.title.toUpperCase() || ''}</h1>
                    </div>
                </div> */}
            </div>
            </div>


            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{page.title || ''}</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>
            
            {/* content */}
            {
                page._rawBody && 
                <section className="pt-0 pb-0 mb-4">
                    <div className="container">
                        <PortableText blocks={page._rawBody} />
                    </div>
                </section>
            }
            


            {/* accordion */}
            {/* <section className="pt-0 pb-2 mb-4">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 mb-12">
                            <AccordionBuilding items={page.categories} />     
                        </div>

                    </div>
                </div>
            </section> */}


        </Layout>
    )
}

export default BasicPageTemplate
